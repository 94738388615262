<template>
    <section class="outContainer">
      <div class="search">
        <!-- <el-select class='each' placeholder="系统类型" v-model="searchInfo.param" clearable @change="chooseType">
          <el-option
            v-for="item in systemTypeEnumOptions"
            :key="item.code"
            :label="item.desc"
            :value="item.code">
          </el-option>
        </el-select> -->
        <el-button size="mini" @click="init" type="primary" icon="el-icon-refresh">刷新</el-button>
        <el-button size="mini" @click="handleAdd(null)">新增</el-button>
      </div>
      <p class="remind">搜索条件为空时，才可对数据进行操作</p>
      <div class="tableContainer">
        <!-- 数据表格 -->
        <el-table
         border
         row-key="id"
         height="100%"
         :data="tableData"
         :tree-props="{children: 'subSampleType', hasChildren: 'hasChildren'}">
          <af-table-column align='left' label="类型名称" prop="name"></af-table-column>
          <af-table-column align='center' label="排序" prop="sort"></af-table-column>
          <el-table-column align='center' label="创建时间" prop="createTimeStr" width="200px"></el-table-column>
          <div v-if="!searchInfo.param">
            <el-table-column align='center' label="操作" fixed="right" width="300">
              <template slot-scope="scope">
                <p style="margin-bottom: 5px;">
                  <el-button size="mini" @click="handleAdd(scope.row,'addChild')" v-if="scope.row.level < 2">新增子类型</el-button>
                  <el-button size="mini" @click="handleAdd(scope.row,'edit')">修改</el-button>
                  <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
                </p>
                  <el-button size="mini" @click="checkTestItems(scope.row)"  v-if="scope.row.parentId &&  !scope.row.subSampleType.length > 0">查看检测项及标准</el-button>
              </template>
            </el-table-column>
          </div>
        </el-table>
        <!-- 新增、编辑 -->
        <el-drawer
          :title="row && row.type != 'edit' ? '新增' : '编辑'"
          :visible.sync="addDrawer"
          size="50%"
          :before-close="handleClose">
          <add v-if="addDrawer" @close='handleClose' ref='newForm' :row="row"></add>
        </el-drawer>
      </div>
    </section>
  </template>
    
    <script>
    import { getSampleTypeList,delSampleType,getsystemTypeEnum } from '@/api/public.js';
    import { timeToTime } from '@/api/publicFun.js';
    import add from './add.vue';

    export default {
      data() {
        return {
          searchInfo:{ // 搜索条件
            param:null,
          },
          tableData: [], // 表格数据
          addDrawer:false, // 是否打开侧边新增、编辑弹框
          row:'', // 当前操作数据
          systemTypeEnumOptions:[], // 系统列表选项
        }
      },
    
      components: {add},
    
      computed: {},
    
      mounted() {
        this.getSystemList();
        this.init();
      },
      methods: {
        // 获取系统列表
        async getSystemList(){
          await getsystemTypeEnum({}).then(res=>{
            if(res.success){
              this.systemTypeEnumOptions = res.data;
            }else{
              this.systemTypeEnumOptions = [];
            }
          })
        },
        // 选择系统类型事件
        chooseType(){
          this.init();
        },
        // 页面初始化
        async init(){
          await getSampleTypeList(this.searchInfo).then(res=>{
            if(res.success){
              this.tableData = res.data;
              this.tableData.forEach(element => {
                element.createTimeStr = timeToTime(element.createTime,'seconds');
              });
            }else{
              this.tableData = [];
            }
            this.addLevelInfo(this.tableData);
          })
        },
        // 层级递归处理数据
        addLevelInfo(data, level = 0) {
          data.forEach(item => {
            item.level = level; // 添加当前层级信息
            item.createTimeStr = timeToTime(item.createTime,'seconds');
            if (item.subSampleType && item.subSampleType.length > 0) {
              this.addLevelInfo(item.subSampleType, level + 1); // 递归处理子节点
            }
          });
        },
        // 打开新增、编辑
        handleAdd(row,type){
          this.row = row;
          if(type){
            this.row.type = type
          }
          this.addDrawer = true;
        },
        // 删除
        handleDel(row){
          this.$confirm('确认进行该操作吗？').then(async () => {
            await delSampleType({param:row.id}).then(res=>{
              this.$message({
                message:res.success ? '操作成功' : res.msg,
                type:res.success ? 'success' : 'fail',
              })
              this.init();
            })
          })
        },
        // 关闭
        handleClose(){
          this.addDrawer = false;
          this.init()
        },
        // 查看检测项及标准
        checkTestItems(row){
          this.$router.push({path:'/foodTypeManage/standards',query:{sampleTypeId:row.id}})
        },
      },
    };
    </script>
    
    <style scoped lang="scss">
      .remind{
        text-align: left;
        line-height: 20px;
        margin-bottom: 10px;
      }
     .menu{
        width: 100%;
        background-color: white;
        height: 60px;
        width: 100%;
        padding: 10px 50px;
        position: relative;
      }
      .menu button{
        position: absolute;
        right:20px;
        top:10px;
      }
      /deep/ .el-table__row--level-0 {
        button{
          border-color: rgb(30, 109, 212);
          color: rgb(30, 109, 212);
        }
        color: rgb(30, 109, 212);
      }
      /deep/ .el-table__row--level-1{
        button{
          border-color: green !important;
          color: green !important;
        }
        color: green !important;
      }
    </style>
    