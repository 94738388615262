<template>
    <section>
      <!-- 表单 -->
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="140px" style="width:95%;overflow:auto">
        <el-form-item label="父级样品类型名称" prop="name" v-if="row && row.type == 'addChild'">
          <p>{{ row.name }}</p>
        </el-form-item>
        <el-form-item label="样品类型名称" prop="name">
          <el-input placeholder="请输入样品类型名称" v-model="form.name">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="所属系统" prop="belongSystem">
            <el-checkbox-group v-model="belongSystem" @change="chooseSystem">
                <el-checkbox v-for="item in systemTypeEnumOptions" :key="item.code" :label="item.code">{{ item.desc }}</el-checkbox>
            </el-checkbox-group>
        </el-form-item> -->
        <el-form-item label="排序" prop="sort">
          <el-input placeholder="请输入排列顺序（数字越小排列越在前）" v-model="form.sort">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { addSampleType,updateSampleType,getsystemTypeEnum } from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{
            parentId:null,
            belongSystem:'',
            testItems:[],
        },
        rules: { // 表单验证
          name: [
            { required: true, message: "样品类型名称不得为空", trigger: "blur" },
          ],
          // belongSystem:[
          //   { required: true, message: "请选择所属系统", trigger: "blur" },
          // ]
        },
        belongSystem:[], // 所属系统多选数据
        imageUrl:'',
        value:[],
        options:[],
        show:true,
        
        systemTypeEnumOptions:[], // 系统类型选项
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
      if(this.row && this.row.type == 'addChild'){
        this.form.parentId = this.row.id;
        // this.form.parentBelongSystem = this.row.belongSystem.split(',');
      }else{
        this.form = {...this.row};
        // this.belongSystem = this.row ? this.form.belongSystem.split(',').map(Number) : [];
      }
    },
  
    methods: {
       close(){
          this.$emit('close');
       },
      // 获取系统列表
      async init(){
        await getsystemTypeEnum({}).then( res => {
          if(res.success){
            this.systemTypeEnumOptions = [...res.data];
            // 判断新增子类型时，可选择的系统类型应为父类型有的
            // if(this.form.parentId){
            //   let data = [];
            //   this.systemTypeEnumOptions.forEach(element => {
            //     for(let i=0;i<this.form.parentBelongSystem.length;i++){
            //       if(this.form.parentBelongSystem[i] == element.code){
            //         data.push(element);
            //         break;
            //       }
            //     }
            //   });
            //   this.systemTypeEnumOptions = [...data];
            // }
          }else{
            this.systemTypeEnumOptions = [];
          }
        })
      },
      // 选择系统事件
      chooseSystem(e){
        this.form.belongSystem = this.belongSystem.join('');
      },

      // 确认新增检测项及其信息
      confirmAddItem(){
        this.$refs["testItemForm"].validate(valid => {
          if(valid){
            this.form.testItems.push(this.testItemForm);
            this.testItemDialog = false;
          }
        })
      },
      // 删除检测项
      deleteTestItem(index){
        this.form.testItems.splice(index,1)
      },
      // 新增
      async add(){
        await addSampleType({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit('close');
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
        .catch(error => {});
      },
      addStandards(){
        addStandards().then(res=>{
          if(res.success){
            
          }
        })
      },
      // 编辑
      async edit(){
        await updateSampleType({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit('close');
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
        .catch(error => {});
      },
      // 提交
      submit(form) {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.form.belongSystem = this.belongSystem.join(',');
            if(this.row && this.row.type == 'edit'){
              this.edit()
            }else{
              this.add();
            }
          } else {
            return false;
          }
        });
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 10px;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  